import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/guards/auth.guard";

export const rootRouterConfig: Routes = [
  // {
  //   path: 'onboarding',
  //   loadChildren: './views/bookone-onboarding/bookone-onboarding.module#BookoneOnboardingModule',
  //   data: { title: 'BookOne Onboarding', breadcrumb: 'OnBoarding' }
  // },

  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      /* {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD' }
      },*/
      {
        path: "master-service",
        loadChildren: () =>
          import("./views/master-service/master-service.module").then(
            (m) => m.MasterServiceModule
          ),
        data: { title: "BookOne Services", breadcrumb: "Services" },
      },
      {
        path: "review",
        loadChildren: () =>
          import("./views/review-module/review-module.module").then(
            (m) => m.ReviewModuleModule
          ),
        data: { title: "Review", breadcrumb: "Review" },
      },
      {
        path: "businessLead",
        loadChildren: () =>
          import("./views/business-lead/business-lead.module").then(
            (m) => m.BusinessLeadModule
          ),
        data: { title: "Business Lead", breadcrumb: "Business Lead" },
      },
      {
        path: "restaurant",
        loadChildren: () =>
          import("./views/restaurant/restaurants/restaurants.module").then(
            (m) => m.RestaurantsModule
          ),
        data: { title: "Restaurant", breadcrumb: "Restaurant" },
      },
      {
        path: "report",
        loadChildren: () =>
          import("./views/report/report.module").then((m) => m.ReportModule),
        data: { title: "BookOne Report", breadcrumb: "Report" },
      },
      {
        path: "order",
        loadChildren: () =>
          import("./views/order/order.module").then((m) => m.OrderModule),
        data: { title: "Order", breadcrumb: "Order" },
      },
      {
        path: "invoice",
        loadChildren: () =>
          import("./views/invoice/invoice.module").then((m) => m.InvoiceModule),
        data: { title: "Invoice", breadcrumb: "Invoice" },
      },
      {
        path: "ems",
        loadChildren: () =>
          import("./views/ems/ems.module").then((m) => m.EmsModule),
        data: { title: "Employee", breadcrumb: "Employee" },
      },
      {
        path: "hrm",
        loadChildren: () =>
          import("./views/hrm/hrm.module").then((m) => m.HrmModule),
        data: { title: "HRM", breadcrumb: "HRM" },
      },
      {
        path: "project",
        loadChildren: () =>
          import("./views/ems/project/project.module").then(
            (m) => m.ProjectModule
          ),
        data: { title: "PROJECT", breadcrumb: "PROJECT" },
      },
      {
        path: "kitchen",
        loadChildren: () =>
          import("./views/kitchen/kitchen.module").then((m) => m.KitchenModule),
        data: { title: "Kitchen", breadcrumb: "Kitchen" },
      },
      {
        path: "inventory",
        loadChildren: () =>
          import(
            "./views/inventory-management/inventory-management.module"
          ).then((m) => m.InventoryManagementModule),
        data: {
          title: "Inventory Management",
          breadcrumb: "Inventory Management",
        },
      },
      // {
      //   path: 'recipe',
      //   loadChildren: './views/recipe/recipe.module#RecipeModule',
      //   data: { title: 'Recipe Management', breadcrumb: 'Recipe Management' }
      // },
      // {
      //   path: 'grocery',
      //   loadChildren: './views/grocery/grocery.module#GroceryModule',
      //   data: { title: 'Grocery', breadcrumb: 'Grocery Management' }
      // },
      {
        path: "Campaign",
        loadChildren: () =>
          import("./views/campaign/campaign.module").then(
            (m) => m.CampaignModule
          ),
        data: { title: "Business Campaign", breadcrumb: "Campaign" },
      },
      {
        path: "business-promotion",
        loadChildren: () =>
          import("./views/business-promotion/business-promotion.module").then(
            (m) => m.BusinessPromotionModule
          ),
        data: { title: "Business Promotion", breadcrumb: "Business Promotion" },
      },
      {
        path: "bookone-admin",
        loadChildren: () =>
          import("./views/bookone-admin/bookone-admin.module").then(
            (m) => m.BookoneAdminModule
          ),
        data: { title: "BookOne Admin", breadcrumb: "Admin" },
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./views/profile/profile.module").then((m) => m.ProfileModule),
        data: { title: "Profile", breadcrumb: "Profile" },
      },
      {
        path: "onboarding",
        loadChildren: () =>
          import("./views/bookone-onboarding/bookone-onboarding.module").then(
            (m) => m.BookoneOnboardingModule
          ),
        data: { title: "BookOne Onboarding", breadcrumb: "onBoarding" },
      },
      // {
      //   path: 'page-layouts',
      //   loadChildren:
      //     './views/page-layouts/page-layouts.module#PageLayoutsModule'
      // },
      // {
      //   path: 'utilities',
      //   loadChildren: './views/utilities/utilities.module#UtilitiesModule'
      // },
      {
        path: "property-onboarding",
        loadChildren: () =>
          import("./views/property-onboarding/property-onboarding.module").then(
            (m) => m.PropertyOnboardingModule
          ),
        data: { title: "Property Onboarding", breadcrumb: "Onboarding" },
      },
      {
        path: "product",
        loadChildren: () =>
          import("./views/product/product.module").then((m) => m.ProductModule),
        data: { title: "Product", breadcrumb: "Product" },
      },
      {
        path: "bookone",
        loadChildren: () =>
          import("./views/bookone/bookone.module").then((m) => m.BookOneModule),
        data: { title: "BookOne", breadcrumb: "BookOne" },
      },
      {
        path: "customer",
        loadChildren: () =>
          import(
            "./views/bookone/manage-customer/customer/customer.module"
          ).then((m) => m.CustomerModule),
        data: { title: "Customer", breadcrumb: "Customer" },
      },
    ],
  },
  {
    path: "bookone-support",
    loadChildren: () =>
      import("./views/bookone-support/bookone-support.module").then(
        (m) => m.BookoneSupportModule
      ),
    data: { title: "BookOne Support", breadcrumb: "Support" },
  },
  {
    path: "bookone-payment",
    loadChildren: () =>
      import("./views/bookone-payment/bookone-payment.module").then(
        (m) => m.BookonePaymentModule
      ),
    data: { title: "BookOne Payment", breadcrumb: "Payment" },
  },
  // {
  //   path: "",
  //   pathMatch: "full",
  //   redirectTo: "sessions/signin",
  // },
  {
    path: "",
    redirectTo: "master-service/home",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () =>
          import("./views/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Session" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
