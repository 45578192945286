import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  public authToken;

  constructor(private router: Router, private authService: JwtAuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {


    if (this.authService.getJwtToken() !== null) {
      console.log('auth guard');
      this.authToken = true;
      return true;
    }
    else {
      this.router.navigate(["/sessions/signin"]);
      return false;
    }
  }
}
