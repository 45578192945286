import { Component, OnInit, Input, Renderer2, ChangeDetectorRef } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth.service';
import { TokenStorage } from './../../../token.storage';
import { Property } from './../../../views/bookone/property/property';
import { ApplicationUser } from '../../../views/sessions/signup/user';
import { BUSINESS_DEFAULT_LOGO } from 'app/app.component';
import { NotificationService } from 'app/shared/services/notification.service';
import { Notifications } from 'app/shared/models/notification.model';
import { CheckUserType } from 'app/checkUserType';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  },
    //  {
    //   name: 'ES',
    //   code: 'es',
    //   flag: 'flag-icon-es'
    // }
  ]
  currentLang = this.availableLangs[0];

  public egretThemes;
  public layoutConf: any;
  public property: Property;
  logoUrl: string;
  applicationUser: ApplicationUser;

  timeIntevalSeconds = 90;
  notifications: Notifications[];

  valueNotification: string;


  roleArray: any = [];
  role: any = [];

  checkUserType: CheckUserType;

  isNotificationShow: boolean = false;

  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private renderer: Renderer2,
    private notificationService: NotificationService,
    public authService: AuthService,
    public token: TokenStorage
  ) {
    this.applicationUser = new ApplicationUser();

    this.roleArray = this.token.getRole();
    this.checkUserType = new CheckUserType();

    this.role = [];
    JSON.parse(this.token.getRole()).forEach(item => {
      this.role.push(item);
    });

    const filters = {
      roles: roles => roles.find(x => this.roleArray.includes(x.toUpperCase())),
    };

    if (this.checkUserType.isHotelAdmin(this.role[0]) == true) {
      if (this.token.getProperty().propertyStatus != undefined && this.token.getProperty().propertyStatus.toLocaleUpperCase() === "COMPLETED") {

        this.isNotificationShow = true;

        let refreshIntervalId = setInterval(() => {
          this.valueNotification = this.token.getNotificationValue();

          if (this.token.getProperty() === null || this.token.getProperty() === undefined) {
            clearInterval(refreshIntervalId);
          }

        }, this.timeIntevalSeconds * 10);
      }
    }
    else {
      this.isNotificationShow = false;
      this.valueNotification = '0';
    }
  }
  ngOnInit() {
    this.property = this.token.getProperty();
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);

    if (this.property.businessType != undefined && this.property.businessType.toLocaleLowerCase() !== 'accommodation') {
      if (this.property.logoUrl === null) {
        this.logoUrl = BUSINESS_DEFAULT_LOGO;
      }
      else {
        this.logoUrl = this.property.imageUrl;
      }
    }
    else {
      this.getUserInfoById(this.token.getUserId());
    }
  }

  getUserInfoById(userId: string) {
    //Logger.log('Application user : ');
    this.authService.getUserByUserId(userId).subscribe(response => {
      //  Logger.log(response);
      this.applicationUser = response.body;

      if (this.applicationUser.logoUrl === null) {
        this.logoUrl = "./../../../../assets/images/logos/profilepicture.svg";
      }
      else {
        this.logoUrl = this.applicationUser.logoUrl;
      }
    });
    // this.changeDetectorRefs.detectChanges();
  }

  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, { transitionClass: true })
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, { transitionClass: true })

  }

  onSearch(e) {
    //   Logger.log(e)
  }

  onHome() {
    this.token.home();
  }
}
