import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { CheckUserType } from "app/checkUserType";
import { TokenStorage } from "app/token.storage";
import { AddSubscriptionService } from "app/views/bookone-admin/services/subscription-service.service";
import { Room } from "app/views/bookone/room/room";

interface IOnboardingItem {
  name: string; // Display text
  state?: string; // Router state
  roles?: any;
}

interface ISubItem {
  name: string; // Display text
  state?: string; // Router state
}

interface IMainItem {
  name: string; // Display text
  type: string; // Router state
  icon: string;
  tooltip: string;
  state: string;
  sub: ISubItem[];
}

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.template.html",
})
export class SidenavComponent implements OnInit {
  @Input("items") public menuItemsMain: any[] = [];
  @Input("hasIconMenu") public hasIconTypeMenuItem: boolean;
  @Input("iconMenuTitle") public iconTypeMenuTitle: string;

  mainItem: any = [];
  subItem: any = [];

  roleArray: any = [];
  role: any = [];
  menuItems: any = [];
  mainArray: any = [];
  adminArray: any = [];
  subscriptionSelected: any = [];

  onboardingPropertyValue: any = [];
  onboardingRoomValue: any = [];

  subscriptionValue: any = [];
  channelManagementValue: any = [];

  subscriptionArray: any = [];
  onBoardingArray: any = [];
  subscriptionFilterArray: any = [];
  onboardingFilterArray: any = [];
  checkUserType: CheckUserType;

  rooms: Room[] = [];
  noOfRoomType: number = 0;

  constructor(
    private token: TokenStorage,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private subcriptionService: AddSubscriptionService
  ) {
    //Logger.log('Side nav'+JSON.stringify(this.menuItemsMain));
    this.roleArray = this.token.getRole();
    this.checkUserType = new CheckUserType();
    this.menuItems = [];
    this.rooms = this.token.getRoomTypes();
  }
  ngOnInit() {
    if (
      this.token.getProperty().noOfRoomType != null &&
      this.token.getProperty().noOfRoomType != undefined
    ) {
      this.noOfRoomType = this.token.getProperty().noOfRoomType;
    } else {
      this.noOfRoomType = 1;
    }

    this.initMenu();
  }

  initMenu() {
    this.role = [];
    JSON.parse(this.token.getRole()).forEach((item) => {
      this.role.push(item);
    });

    const filters = {
      roles: (roles) =>
        roles.find((x) => this.roleArray.includes(x.toUpperCase())),
    };
    //...
    this.mainArray = [];
    this.adminArray = [];

    if (this.checkUserType.isHotelAdmin(this.role[0]) == true) {
      this.menuItems = [];

      // this.onboardingValue = this.menuItemsMain[2];

      this.onboardingPropertyValue = this.menuItemsMain[2];
      this.onboardingRoomValue = this.menuItemsMain[2];

      this.subscriptionValue = this.menuItemsMain[1];

      this.mainArray = this.filterArray(this.menuItemsMain, filters);

      if (
        this.token.getProperty() === null ||
        this.token.getProperty() === undefined ||
        this.token.getProperty().propertyStatus === undefined ||
        this.token.getProperty().propertyStatus === null ||
        this.token.getProperty().propertyStatus === "NEW"
      ) {
        if (
          this.token.getProperty().businessType != undefined &&
          this.token.getProperty().businessType.toLocaleLowerCase() !==
            "accommodation"
        ) {
          this.onBoadringBusinessDetailsFilter("PROPERTY");
        } else {
          //this.onBoadringBusinessDetailsFilter("PROPERTY");
          // this.menuItems =this.mainArray;
          this.onBoadringPropertyFilter("PROPERTY");
        }
      }
      // room onboarding part
      else if (
        this.token.getProperty().businessType != undefined &&
        this.token.getProperty().businessType.toLocaleLowerCase() ===
          "accommodation" &&
        this.token.getProperty().propertyStatus != undefined &&
        this.token.getProperty().propertyStatus === "COMPLETED" &&
        this.rooms === null
      ) {
        // this.menuItems =this.mainArray;
        this.onBoadringRoomFilter("ROOM");
      }
      // else  if(this.token.getProperty().businessType.toLocaleLowerCase() === 'accommodation' && this.token.getProperty().propertyStatus==="COMPLETED" &&  this.token.getRoomTypes() !=null &&  this.token.getRoomTypes() != undefined &&  this.token.getRoomTypes().length < this.noOfRoomType) {
      //   this.onBoadringRoomFilter("ROOM");
      // }
      else {
        if (
          this.token.getProperty().businessType != undefined &&
          this.token.getProperty().businessType.toLocaleLowerCase() ===
            "accommodation"
        ) {
          if (
            this.token.getPropertyId() != null ||
            this.token.getPropertyId() != undefined
          ) {
            this.menuItems = this.mainArray;
            // this.getSubscriptionForProperty(parseInt(this.token.getPropertyId()));
            if (this.checkUserType.isPropAdmin(this.role[0]) == true) {
              this.getSubscriptionFormTokenStorage();
            } else {
              this.getSubscriptionForRole(this.role[0]);
            }
          }
        } else {
          this.menuItems = this.mainArray;
          this.changeDetectorRefs.detectChanges();
        }
      }
    } else {
      this.menuItems = this.menuItemsMain;
      this.changeDetectorRefs.detectChanges();
    }
  }

  BusinessServiceMenu() {
    this.subItem = [];
    this.mainItem = [];

    let subItemvalue: ISubItem = {
      name: "Business Profile",
      state: "/business-dashboard/profile",
    };
    this.subItem.push(subItemvalue);

    let mainItemValue: IMainItem = {
      name: "Business",
      icon: "book",
      state: "master-services",
      tooltip: "Business",
      type: "dropDown",
      sub: this.subItem,
    };

    this.mainItem.push(mainItemValue);

    this.menuItems.push(this.mainItem);
    this.changeDetectorRefs.detectChanges();
  }
  onBoadringPropertyFilter(filterValue: string) {
    this.onboardingFilterArray = [];
    this.onBoardingArray = [];

    let roomonboarding: IOnboardingItem = {
      name: "Add Property",
      state: "on-boarding-property/0/0/0",
      roles: ["PROPERTY"],
    };

    this.onBoardingArray.push(roomonboarding);

    this.onboardingRoomValue.sub = this.onBoardingArray;
    // Logger.log(filterValue+'- this.onBoardingArray '+JSON.stringify(this.onBoardingArray));
    this.menuItems.push(this.onboardingPropertyValue);
    this.changeDetectorRefs.detectChanges();
  }

  onBoadringBusinessDetailsFilter(filterValue: string) {
    this.onboardingFilterArray = [];
    this.onBoardingArray = [];

    let roomonboarding: IOnboardingItem = {
      name: "Business Details Setup",
      state: "on-boarding-property/0/0/0",
      roles: ["PROPERTY"],
    };

    this.onBoardingArray.push(roomonboarding);

    this.onboardingRoomValue.sub = this.onBoardingArray;
    // Logger.log(filterValue+'- this.onBoardingArray '+JSON.stringify(this.onBoardingArray));
    this.menuItems.push(this.onboardingPropertyValue);
    this.changeDetectorRefs.detectChanges();
  }

  onBoadringRoomFilter(filterValue: string) {
    this.onboardingFilterArray = [];
    this.onBoardingArray = [];

    let roomonboarding: IOnboardingItem = {
      name: "Add Room",
      state: "room",
      roles: ["ROOM"],
    };

    this.onBoardingArray.push(roomonboarding);

    this.onboardingRoomValue.sub = this.onBoardingArray;
    this.menuItems.push(this.onboardingRoomValue);
    this.changeDetectorRefs.detectChanges();
  }

  filterArray(array, filters) {
    const filterKeys = Object.keys(filters);
    return array.filter((item) => {
      // validates all filter criteria
      return filterKeys.every((key) => {
        // ignores non-function predicates
        if (typeof filters[key] !== "function") return true;
        return filters[key](item[key]);
      });
    });
  }
  getSubscriptionForRole(roleName: string) {
    this.subscriptionFilterArray = [];

    this.subscriptionFilterArray.push(roleName.trim().toUpperCase());

    const filtersSubscriptions = {
      roles: (roles) =>
        roles.find((x) =>
          this.subscriptionFilterArray.includes(x.toUpperCase())
        ),
    };

    this.subscriptionArray = this.filterArray(
      this.menuItemsMain[1].sub,
      filtersSubscriptions
    );

    this.menuItemsMain[1].sub = this.subscriptionArray;
    this.menuItems.push(this.menuItemsMain[1]);
    this.changeDetectorRefs.detectChanges();
  }

  getSubscriptionFormTokenStorage() {
    this.subscriptionSelected = this.token.getProperty().subscriptionList;
    // Logger.log('hotel admin'+JSON.stringify(this.subscriptionSelected));
    if (
      this.subscriptionSelected != null &&
      this.subscriptionSelected != undefined &&
      this.subscriptionSelected.length > 0
    ) {
      this.subscriptionFilterArray = [];

      if (
        this.token.getProperty().businessType != undefined &&
        this.token.getProperty().businessType.toLocaleLowerCase() ===
          "accommodation"
      ) {
        this.subscriptionFilterArray.push("PROPERTY MANAGEMENT");
      } else {
        this.subscriptionFilterArray.push("BUSINESS_PROFILE");
      }

      for (let i = 0; i < this.subscriptionSelected.length; i++) {
        this.subscriptionFilterArray.push(
          this.subscriptionSelected[i].name.trim().toUpperCase()
        );
      }

      const filtersSubscriptions = {
        roles: (roles) =>
          roles.find((x) =>
            this.subscriptionFilterArray.includes(x.toUpperCase())
          ),
      };

      this.subscriptionArray = this.filterArray(
        this.menuItemsMain[1].sub,
        filtersSubscriptions
      );

      this.menuItemsMain[1].sub = this.subscriptionArray;
      this.menuItems.push(this.menuItemsMain[1]);
      this.changeDetectorRefs.detectChanges();
    }
  }

  getSubscriptionForProperty(propertyId: number) {
    this.subcriptionService
      .getPropertySubcription(String(propertyId))
      .subscribe(
        (data) => {
          this.subscriptionSelected = data;
          // Logger.log('hotel admin'+JSON.stringify(this.subscriptionSelected));
          if (this.subscriptionSelected.length > 0) {
            this.subscriptionFilterArray = [];

            if (
              this.token.getProperty().businessType.toLocaleLowerCase() ===
              "accommodation"
            ) {
              this.subscriptionFilterArray.push("PROPERTY MANAGEMENT");
            } else {
              this.subscriptionFilterArray.push("BUSINESS_PROFILE");
            }

            for (let i = 0; i < this.subscriptionSelected.length; i++) {
              // Logger.log(this.subscriptionSelected[i].name.toUpperCase());

              this.subscriptionFilterArray.push(
                this.subscriptionSelected[i].name.trim().toUpperCase()
              );
            }

            const filtersSubscriptions = {
              roles: (roles) =>
                roles.find((x) =>
                  this.subscriptionFilterArray.includes(x.toUpperCase())
                ),
            };

            this.subscriptionArray = this.filterArray(
              this.menuItemsMain[1].sub,
              filtersSubscriptions
            );

            this.menuItemsMain[1].sub = this.subscriptionArray;
            this.menuItems.push(this.menuItemsMain[1]);
          }
        },
        (error) => {}
      );
  }

  getSubscriptionFororganization(organisationId: number) {
    this.subcriptionService
      .getOrganizationSubcription(String(organisationId))
      .subscribe(
        (data) => {
          this.subscriptionSelected = data;

          if (this.subscriptionSelected.length > 0) {
            this.subscriptionFilterArray = [];
            for (let i = 0; i < this.subscriptionSelected.length; i++) {
              // Logger.log(this.subscriptionSelected[i].name.toUpperCase());

              this.subscriptionFilterArray.push(
                this.subscriptionSelected[i].name.toUpperCase()
              );
            }

            const filtersSubscriptions = {
              roles: (roles) =>
                roles.find((x) =>
                  this.subscriptionFilterArray.includes(x.toUpperCase())
                ),
            };

            this.subscriptionArray = this.filterArray(
              this.menuItemsMain[1].sub,
              filtersSubscriptions
            );

            this.menuItemsMain[1].sub = this.subscriptionArray;
            this.menuItems.push(this.menuItemsMain[1]);
            //  Logger.log('this.subscriptionArray  '+JSON.stringify(this.subscriptionArray));
          }
        },
        (error) => {}
      );
  }
  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: "ITEM",
      type: "dropDown",
      tooltip: "Item",
      icon: "done",
      state: "material",
      sub: [
        { name: "SUBITEM", state: "cards" },
        { name: "SUBITEM", state: "buttons" },
      ],
    });
  }
}
