import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TokenStorage } from "../../token.storage";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles?: any;
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  roles?: any;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}
interface UserRole {
  name: string; // Display text
}

@Injectable()
export class OrgNavigationService {
  roleArray: any = [];
  userId: string;

  constructor(private token: TokenStorage) {
    this.userId = this.token.getUserId();
    // Logger.log('this.userId in navigation service--'+ this.userId +'-org Id-'+ this.token.getOrganizationId());
  }
  // tslint:disable-next-line: member-ordering

  // customer support menu

  customer_support_Menu: IMenuItem[] = [
    {
      name: "Dashboard",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "bookone/su-dashboard",
      roles: ["ORG_ADMIN"],
      // sub: [
      //   { name: 'Dashboard', state: 'su-dashboard' },
      //   // { name: 'Organisation Dashboard', state: 'org-dashboard' },
      // ],
    },
    {
      name: "Organisation Admin",
      type: "dropDown",
      tooltip: "Bookone Admin",
      icon: "book",
      roles: ["ORG_ADMIN"],
      sub: [
        // {
        //   name: "User Management",
        //   type: "dropDown",
        //   icon: "book",
        //   roles: ["ORG_ADMIN"],
        //   state: "bookone-admin",
        //   sub: [
        //     { name: "Add User", state: "create-user/0/0/0" },
        //     { name: "Manage User", state: "manage-user" },
        //   ],
        // },
        // {
        //   name: "B2B",
        //   type: "dropDown",
        //   icon: "book",
        //   roles: ["ORG_ADMIN"],
        //   state: "bookone-admin",
        //   sub: [{ name: "Add Company", state: "companyList" }],
        // },
        // {
        //   name: "Property Management",
        //   type: "dropDown",
        //   icon: "book",
        //   roles: ["ORG_ADMIN"],
        //   state: "bookone-admin",
        //   sub: [{ name: "Property List", state: "organisation-property-list" }],
        // },
        // {
        //   name: "Msg Tracker",
        //   type: "dropDown",
        //   icon: "book",
        //   roles: ["ORG_ADMIN"],
        //   state: "bookone-admin",
        //   sub: [{ name: "Message Tracker", state: "msg-tracker" }],
        // },
        // {
        //   name: "Business Type Config",
        //   type: "dropDown",
        //   icon: "book",
        //   roles: ["ORG_ADMIN"],
        //   state: "bookone-admin",
        //   sub: [{ name: "Business Type List", state: "service-config" }],
        // },
        // {
        //   name: "Business Lead",
        //   type: "dropDown",
        //   icon: "book",
        //   roles: ["ORG_ADMIN"],
        //   state: "businessLead",
        //   sub: [{ name: "Business Lead", state: "manage-businesslead" }],
        // },

        // {
        //   name: "Order",
        //   type: "link",
        //   icon: "receipt",
        //   state: "order/order-list",
        //   roles: ["PROP_ADMIN"],
        //   // sub: [{ name: 'Order', state: 'order-list' }],
        // },
        // {
        //   name: "Room Facilities",
        //   type: "link",
        //   icon: "receipt",
        //   state: "bookone-admin/room-facilities",
        //   roles: ["PROP_ADMIN"],
        //   // sub: [{ name: 'Order', state: 'order-list' }],
        // },
        // {
        //   name: "Manage Booking",
        //   type: "link",
        //   icon: "receipt",
        //   state: "bookone/manage-booking/list",
        //   roles: ["PROP_ADMIN"],
        //   // sub: [{ name: 'Order', state: 'order-list' }],
        // },
        {
          name: "Manage Enquiries",
          type: "link",
          icon: "receipt",
          state: "bookone-admin/manage-enquiries",
          roles: ["PROP_ADMIN"],
          // sub: [{ name: 'Order', state: 'order-list' }],
        },
        // {
        //   name: "Manage Services",
        //   type: "link",
        //   icon: "receipt",
        //   state: "bookone-admin/manage-service-request",
        //   roles: ["ORG_ADMIN"],
        //   // sub: [{ name: 'Order', state: 'order-list' }],
        // },
      ],
    },
  ];

  // org admin menu
  iconMenu: IMenuItem[] = [
    {
      name: "BOOKONE",
      type: "dropDown",
      tooltip: "Dashboard",
      icon: "dashboard",

      state: "bookone",
      roles: ["ORG_ADMIN"],
      sub: [
        { name: "Dashboard", state: "su-dashboard" },
        // { name: 'Organisation Dashboard', state: 'org-dashboard' },
      ],
    },
    {
      name: "Organisation Admin",
      type: "dropDown",
      tooltip: "Bookone Admin",
      icon: "book",
      roles: ["ORG_ADMIN"],
      sub: [
        {
          name: "User Management",
          type: "dropDown",
          icon: "book",
          roles: ["ORG_ADMIN"],
          state: "bookone-admin",
          sub: [
            { name: "Add User", state: "create-user/0/0/0" },
            { name: "Manage User", state: "manage-user" },
          ],
        },
        {
          name: "B2B",
          type: "dropDown",
          icon: "book",
          roles: ["ORG_ADMIN"],
          state: "bookone-admin",
          sub: [{ name: "Add Company", state: "companyList" }],
        },
        {
          name: "Eglobe Property",
          type: "dropDown",
          icon: "book",
          roles: ["ORG_ADMIN"],
          state: "bookone-admin",
          sub: [{ name: "Add Property", state: "eglobe-property-list" }],
        },
        {
          name: "Subscription Management",
          type: "dropDown",
          icon: "book",
          roles: ["ORG_ADMIN"],
          state: "bookone-admin",
          sub: [
            { name: "Add Subscription", state: "create-subcription/0/0" },
            { name: "Manage Subscription", state: "manage-subcription" },
          ],
        },
        {
          name: "OTA Channel Management",
          type: "dropDown",
          icon: "book",
          roles: ["ORG_ADMIN"],
          state: "bookone-admin",
          sub: [
            {
              name: "OTA Channel Configure",
              state: "channel-configure/0/" + "0/0",
            },
          ],
        },
        {
          name: "Property Management",
          type: "dropDown",
          icon: "book",
          roles: ["ORG_ADMIN"],
          state: "bookone-admin",
          sub: [{ name: "Property List", state: "organisation-property-list" }],
        },
        {
          name: "Msg Tracker",
          type: "dropDown",
          icon: "book",
          roles: ["ORG_ADMIN"],
          state: "bookone-admin",
          sub: [{ name: "Msg Tracker", state: "msg-tracker" }],
        },
        {
          name: "Business Type Config",
          type: "dropDown",
          icon: "book",
          roles: ["ORG_ADMIN"],
          state: "bookone-admin",
          sub: [{ name: "Business Type List", state: "service-config" }],
        },
        // {
        //   name: "Business Lead",
        //   type: "dropDown",
        //   icon: "book",
        //   roles: ["ORG_ADMIN"],
        //   state: "businessLead",
        //   sub: [{ name: "Business Lead", state: "manage-businesslead" }],
        // },
        {
          name: "Business Promotion",
          type: "dropDown",
          icon: "book",
          roles: ["ORG_ADMIN"],
          state: "business-promotion",
          sub: [{ name: "Business Promotion", state: "list" }],
        },
        {
          name: "Business Campaign",
          type: "dropDown",
          icon: "receipt",
          state: "Campaign",
          roles: ["PROP_ADMIN"],
          sub: [{ name: "Business Campaign", state: "list" }],
        },
        {
          name: "Business Review",
          type: "dropDown",
          icon: "receipt",
          state: "review",
          roles: ["PROP_ADMIN"],
          sub: [{ name: "Review List", state: "list" }],
        },
        {
          name: "Order",
          type: "link",
          icon: "receipt",
          state: "order/order-list",
          roles: ["PROP_ADMIN"],
          // sub: [{ name: 'Order', state: 'order-list' }],
        },
        {
          name: "Room Facilities",
          type: "link",
          icon: "receipt",
          state: "bookone-admin/room-facilities",
          roles: ["PROP_ADMIN"],
          // sub: [{ name: 'Order', state: 'order-list' }],
        },
        {
          name: "Manage Booking",
          type: "dropDown",
          icon: "receipt",
          state: "bookone/manage-booking",
          roles: ["PROP_ADMIN"],
          sub: [
          //   {
          //   name: "Manage bookings",
          //   type: "link",
          //   icon: "receipt",
          //   state:"bookone/manage-booking/list",
          //   roles: ["PROP_ADMIN"],
          //   // sub: [{ name: 'Order', state: 'order-list' }],
          // },
            { name: "Property Bookings", state: "property-booking" },
            { name: "All Bookings", state: "all-booking" },
          ],
        },
        // {
        //   name: "Manage Booking",
        //   type: "dropdown",
        //   icon: "receipt",
        //   state: "bookone/manage-booking/list",
        //   roles: ["PROP_ADMIN"],

        // },
        {
          name: "Manage Enquiries",
          type: "link",
          icon: "receipt",
          state: "bookone-admin/manage-enquiries",
          roles: ["PROP_ADMIN"],
          // sub: [{ name: 'Order', state: 'order-list' }],
        },
        {
          name: "Manage Invoice",
          type: "link",
          icon: "receipt",
          state: "/invoice/invoice-list",
          roles: ["PROP_ADMIN"],
          // sub: [{ name: 'Order', state: 'order-list' }],
        },
        // {
        //   name: "Manage Services",
        //   type: "link",
        //   icon: "receipt",
        //   state: "bookone-admin/manage-service-request",
        //   roles: ["ORG_ADMIN"],
        //   // sub: [{ name: 'Order', state: 'order-list' }],
        // },

        {
          name: "Report",
          type: "dropDown",
          icon: "book",
          state: "report",
          roles: ["Reports"],
          sub: [
            { name: "Dashboard", state: "report-dashboard" },
            // { name: "Checkin/Police Report", state: "report-view" },
            // { name: "Financial Summary Report", state: "financials-report" },
            // { name: "Occupancy Report", state: "room-rateplan-report" },
            // { name: "Arrival Report", state: "arrival-report" },
            // { name: "Departure Report", state: "departure-report" },
            // { name: "Cashier Report", state: "payment-report" },
            // { name: "Room Status Report", state: "room-status-report" },
            // { name: "GST Report", state: "gst-report" },
            // { name: "Forecast Report", state: "forcast-report" },
            // { name: "Product Report", state: "product-report" },
            // { name: "Guest In House Report", state: "guest-in-house" },
            // { name: "Channel Productivity Report", state: "productivity-report" },
            // { name: "Add On Service Report", state: "service-report" },
            // { name: "Expense Report", state: "expense-report" },
            // { name: "Order Report", state: "order-report" },
            // { name: "Booking Report", state: "booking-report" }
          ],
        },
      ],
    },
  ];

  onBoardingURL(): string {
    return (
      "property/" +
      this.token.getUserId() +
      "/" +
      this.token.getOrganizationId() +
      ""
    );
  }

  // tslint:disable-next-line: member-ordering
  separatorMenu: IMenuItem[] = [
    {
      name: "BOOKONE",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "bookone",
    },
  ];

  plainMenu: IMenuItem[] = [
    {
      name: "BOOKONE",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard",
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  menuItems$ = this.menuItems.asObservable();

  menuItemsCustomerSupport = new BehaviorSubject<IMenuItem[]>(
    this.customer_support_Menu
  );
  menuItemsCustomerSupport$ = this.menuItemsCustomerSupport.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
