import { BusinessService } from "./../../master-service/model/businessService";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { API_URL, API_URL_PROMOTION } from "./../../../app.component";
import { Order } from "../model/order";
import { ProductGroup } from "app/views/product/model/productGroup";
import { BusinessProperties } from "../model/businessUser/businessuser";
import { Recipe } from "app/views/recipe/recipe";
import { TokenStorage } from "app/token.storage";
import { KOT } from "../model/KOT";
import { AllocatedResource } from "../model/allocatedResource";
import { Service } from "app/views/bookone/service/service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(private http: HttpClient, private token: TokenStorage) {}

  authenticateInvoice(orderId: number, userId : number , pin : number) {
    return this.http.get<any>(
      API_URL +
        "/api/order/authenticateInvoice?orderId="+orderId+"&userId="+userId+"&pin="+pin,
      { observe: "response" }
    );
  }

  getOrderByPropertyRevId(revId: string) {
    return this.http.get<Order>(
      API_URL +
        "/api/order/findByPropertyReservationNumber?PropertyReservationNumber=" +
        revId,
      { observe: "response" }
    );
  }

  findProductsByBusinessServiceId(businessServiceId: number) {
    return this.http.get<ProductGroup[]>(
      API_URL + "/api/website/getProductList/" + businessServiceId,
      { observe: "response" }
    );
  }

  orderFindByPropertyReservationNumber(revId: string) {
    return this.http.get<Order>(
      API_URL +
        "/api/order/findByPropertyReservationNumber?PropertyReservationNumber=" +
        revId,
      { observe: "response" }
    );
  }

  getOrderByPropertyIdAndDateRange(
    propertyId: string,
    FromDate: string,
    ToDate: string
  ) {
    return this.http.get<Order[]>(
      API_URL +
        "/api/order/orderByPropertyIdAndDateRange?PropertyId=" +
        propertyId +
        "&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate,
      { observe: "response" }
    );
  }

  getOrderByPropertyIdAndDateRangeWithoutLine(
    propertyId: string,
    FromDate: string,
    ToDate: string
  ) {
    return this.http.get<Order[]>(
      API_URL +
        "/api/order/orderByPropertyIdAndDateRange?PropertyId=" +
        propertyId +
        "&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate +"&hideLine=true",
      { observe: "response" }
    );
  }



  getAllocatedTablesByPropertyIdAndDateRange(
    propertyId: string,
    FromDate: string,
    ToDate: string
  ) {
    return this.http.get<AllocatedResource[]>(
      API_URL +
        "/api/order/getAllocatedTablesByPropertyIdAndDateRange?PropertyId="+propertyId+"&FromDate="+FromDate+"&ToDate=" +
        ToDate,
      { observe: "response" }
    );
  }

  findTotalCountOfOrders(propertyId: string, FromDate: string, ToDate: string) {
    return this.http.get<Order>(
      API_URL +
        "/api/order/totalCountByPropertyIdAndDateRange?PropertyId=" +
        propertyId +
        "&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate,
      { observe: "response" }
    );
  }

  paginationOrderByPropertyIdAndDateRange(
    propertyId: string,
    FromDate: string,
    ToDate: string,
    pageNo: number,
    pageSize: number
  ) {
    return this.http.get<Order[]>(
      API_URL +
        "/api/order/orderByPropertyIdAndDateRange?PropertyId=" +
        propertyId +
        "&FromDate=" +
        FromDate +
        "&ToDate=" +
        ToDate +
        "&pageNo=" +
        pageNo +
        "&pageSize=" +
        pageSize +"&hideLine=true",
      { observe: "response" }
    );
  }

  findByBookingReferenceNumber(propertyReservationNumber: string) {
    return this.http.get<Order[]>(
      API_URL +
        "/api/order/findByBookingReferenceNumber?BookingReferenceNumber=" +
        propertyReservationNumber,
      { observe: "response" }
    );
  }

  findByPropertyId(id: string) {
    return this.http.get<any>(API_URL + "/api/website/findByPropertyId/" + id, {
      observe: "response",
    });
  }

  getOrderListByPropertyId(propertyId: number) {
    return this.http.get<Order[]>(
      API_URL + "/api/order/findByPropertyId?PropertyId=" + propertyId,
      { observe: "response" }
    );
  }

  findAllIncompletedOrderByPropertyIdDateRange(propertyId: number, fromDate : string , toDate  : string) {
    return this.http.get<Order[]>(
      API_URL + "/api/order/findAllIncompletedOrderByPropertyIdDateRange?propertyId=" + propertyId+"&fromDate="+fromDate+"&toDate="+toDate,
      { observe: "response" }
    );
  }

  getOrderByOrderId(orderId: number) {
    return this.http.get<Order>(
      API_URL + "/api/order/findById?OrderId=" + orderId,
      { observe: "response" }
    );
  }

  calculateOutstandingAmount(orderId: number) {
    return this.http.get<number>(
      API_URL + "/api/order/calculateOutstandingAmount/" + orderId,
      { observe: "response" }
    );
  }

  findServicesByOrderId(orderId: number) {
    return this.http.get<Service[]>(
      API_URL + "/api/order/findServicesByOrderId/" + orderId,
      { observe: "response" }
    );
  }


  deleteKotById(id) {
    return this.http.post<any>(
      API_URL + "/api/kot/deleteKotById/"+id,
      { observe: "response" }
    );
  }

  deleteKOTOrderLineId(kotId: number , productCode : string) {
    return this.http.post<any>(
      API_URL + "/api/kot/"+kotId+"/removeKOTOrderLine/"+productCode,
      { observe: "response" }
    );
  }

  createKot(kot:KOT) {
    return this.http.post<KOT>(
      API_URL + "/api/kot/create" , kot,
      { observe: "response" }
    );
  }

  removeKotItem(kotId : number, productCode : string) {
    return this.http.post<KOT>(
      API_URL + "/api/kot/"+kotId+"/removeKOTOrderLine/"+productCode,
      { observe: "response" }
    );
  }


  updateKotLine(kotId : number, lines:any[]) {
    return this.http.post<any>(
      API_URL + "/api/kot/updateKOTOrderLine/"+kotId , lines,
      { observe: "response" }
    );
  }

  updateKotOrderLineItemStatus(kotId : number, status: string) {
    return this.http.get<any>(
      API_URL + "/api/kot/"+kotId+"/updateStatus/"+status,
      { observe: "response" }
    );
  }


  getConfirmOrderByOrderId(orderId: number) {
    return this.http.get<Order>(
      API_URL + "/api/order/confirmOrder/" + orderId,
      { observe: "response" }
    );
  }

  getOrderCancelByOrderId(orderId: number) {
    return this.http.get<Order>(API_URL + "/api/order/cancelOrder/" + orderId, {
      observe: "response",
    });
  }

  getOrderDeletedByOrderId(orderId: number) {
    return this.http.get<Order>(
      API_URL + "/api/organisation/deleteOrderById/" + orderId,
      { observe: "response" }
    );
  }

  getBusinessServiceById(serviceId: number) {
    return this.http.get<BusinessService>(
      API_URL + "/api/businessService/" + serviceId,
      { observe: "response" }
    );
  }

  updateOrderStatus(orderId: number, status: string) {
    return this.http.get<Order>(
      API_URL +
        "/api/order/updateOrderStatus/" +
        orderId +
        "/OrderStatus/" +
        status,
      { observe: "response" }
    );
  }
  updateOrderPaymentStatus(orderId: number, status: string) {
    return this.http.get<Order>(
      API_URL +
        "/api/order/updateOrderStatus/" +
        orderId +
        "/PaymentStatus/" +
        status,
      { observe: "response" }
    );
  }
  updateOrderAmount(order: Order) {
    return this.http.post<Order>(
      API_URL + "/api/order/updateOrderAmount",
      order,
      { observe: "response" }
    );
  }

  saveOrder(order: Order) {
    return this.http.post<Order>(API_URL + "/api/order", order, {
      observe: "response",
    });
  }

  updateOrderLineStatus(orderLineId: number, status: string) {
    return this.http.get<Order>(
      API_URL + "/api/orderline/" + orderLineId + "/status/" + status + "/",
      { observe: "response" }
    );
  }
  deleteOrderLineById(orderLineId: number) {
    return this.http.post<any>(
      API_URL + "/api/orderline/" + orderLineId + "/delete/",
      { observe: "response" }
    );
  }
  getOfferDetailsBySeoFriendlyName(seoName: string) {
    return this.http.get<any>(
      API_URL_PROMOTION + "/api/offer/findBySeofriendlyName/" + seoName + "/",
      { observe: "response" }
    );
  }

  getOrderByCustomerIdAndPropertyId(propertyId: number, customerId: number) {
    return this.http.get<Order[]>(
      API_URL +
        "/api/website/findOrdersByPropertyAndCustomerId?PropertyId=" +
        propertyId +
        "&CustomerId=" +
        customerId,
      { observe: "response" }
    );
  }

  // headers = new Headers();
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     CUSTOMER_ID: this.token.getUserId(),
  //   }),
  //   headers: new HttpHeaders({

  //   }),
  // };

  // orderList() {
  //   return this.http.get<Order[]>(
  //     API_URL + "/api/customer/order",
  //     this.httpOptions
  //   );
  // }
}
