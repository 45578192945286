<footer class="main-footer">
  <div class="container-dynamic d-flex">
    <a
      *ngIf="
        token.getProperty() != null &&
        token.getProperty() != undefined &&
        token.getProperty().seoFriendlyName != null &&
        token.getProperty().seoFriendlyName != undefined
      "
      mat-raised-button
      color="accent"
      href="http://bookonelocal.in/#/listing/{{
        token.getProperty().seoFriendlyName
      }}"
      target="_blank"
      >Go BookOne Local</a
    >
    <span class="m-auto"></span>
    Design & Developed by&nbsp;<a href="https://credencesoft.in"
      >CredenceSoft </a
    >&nbsp;
    <img
      src="assets\images\Logos_CreedenceSoft Icon Black.png"
      height="20px"
      alt=""
    />
  </div>
</footer>
