import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { ApplicationUser } from "./../../../views/sessions/signup/user";
import { Logger } from "../../../services/logger.service";
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { OrgNavigationService } from "../../../shared/services/OrgNavigationService";
import { PropNavigationService } from "../../../shared/services/PropNavigationService";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { AuthService } from "../../../services/auth.service";
import { TokenStorage } from "./../../../token.storage";
import { Property } from "./../../../views/bookone/property/property";
import { CheckUserType } from "../../../checkUserType";
import { BusinessNavigationService } from "app/shared/services/BusinessNavigationService";
import { BUSINESS_DEFAULT_LOGO, DEFAULT_IMAGE } from "app/app.component";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html",
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public menuItemsMain: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  public property: Property;

  role: any = [];

  subscriptionSelected: any = [];

  subscriptionArray: any = [];
  subscriptionFilterArray: any = [];
  checkUserType: CheckUserType;

  isViewLoaded: boolean = false;
  isRefreshCall: boolean = false;

  PropertyStatus: string;
  applicationUser: ApplicationUser;

  constructor(
    private businessNavigationService: BusinessNavigationService,
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    private changeDetectorRefs: ChangeDetectorRef,
    private propNavService: PropNavigationService,
    private orgNavService: OrgNavigationService,
    private authService: AuthService,
    public jwtAuthService: JwtAuthService,
    private token: TokenStorage
  ) {
    this.checkUserType = new CheckUserType();
    this.property = new Property();
    this.applicationUser = new ApplicationUser();

    this.property = this.token.getProperty();

    if (this.property.logoUrl === undefined || this.property.logoUrl === null) {
      this.property.logoUrl = BUSINESS_DEFAULT_LOGO;
    }
    // Logger.log('property side bar side info image : '+this.property.logoUrl);

    this.role = [];
    JSON.parse(this.token.getRole()).forEach((item) => {
      this.role.push(item);
    });
  }

  ngOnInit() {
    this.initView();

    if (this.token.getUserId() != null && this.token.getUserId() != undefined) {
      this.getUserInfoById(this.token.getUserId());
    }
  }

  getUserInfoById(userId: string) {
    //Logger.log('Application user : ');
    this.authService.getUserByUserId(userId).subscribe((response) => {
      //  Logger.log(response);
      this.applicationUser = response.body;

      // if (this.applicationUser.logoUrl === null) {
      //   this.logoUrl = "assets/images/logos/profilepicture.svg";
      // }
      // else {
      //   this.logoUrl = this.applicationUser.logoUrl;
      // }
    });
    // this.changeDetectorRefs.detectChanges();
  }

  refresh() {
    this.isRefreshCall = false;
    this.menuItems = [];
    this.isViewLoaded = false;

    setTimeout(() => {
      Logger.log("refresh");
      this.initView();
    }, 100);
  }

  autoRefresh() {
    this.isRefreshCall = true;
    this.menuItems = [];
    this.isViewLoaded = false;

    setTimeout(() => {
      this.initView();
    }, 3000);
  }

  initView() {
    this.isViewLoaded = false;

    if (this.checkUserType.isSuperAdmin(this.role[0]) == true) {
      this.superAdmin();
    } else if (this.checkUserType.isOrganization(this.role[0]) == true) {
      this.orgAdmin();
    } else if (
      this.checkUserType.isOrganizationCustomerSupport(this.role[0]) == true
    ) {
      Logger.log("customer support sidebar side");
      this.orgCustomerSupportAdmin();
    } else if (this.checkUserType.isHotelAdmin(this.role[0]) == true) {
      if (
        this.token.getProperty().businessType != undefined &&
        this.token.getProperty().businessType.toLocaleLowerCase() !==
          "accommodation" &&
        this.token.getProperty().propertyStatus != undefined &&
        this.token.getProperty().propertyStatus == "COMPLETED"
      ) {
        // Logger.log('plan'+this.token.getProperty().plan);
        if (
          this.token.getProperty().plan === undefined ||
          this.token.getProperty().plan === null ||
          this.token.getProperty().plan === "Business Starter"
        ) {
          this.BusinessServiceStater();
        } else if (
          this.token.getProperty().plan != undefined &&
          this.token.getProperty().plan === "Business Premium"
        ) {
          this.BusinessServicePremium();
        } else if (
          this.token.getProperty().plan != undefined &&
          this.token.getProperty().plan === "Business Essentials"
        ) {
          this.BusinessServiceEssential();
        }
        // }
        //  else if (this.checkUserType.isMarketingManager(this.role[0]) == true) {
        //   Logger.log("marketing manager");
        //   this.marketingManager();
      } else {
        this.hotelAdmin();
      }
    }

    this.layoutConf = this.layout.layoutConf;

    if (this.isRefreshCall === false) {
      this.autoRefresh();
    }
  }

  BusinessServiceEssential() {
    this.iconTypeMenuTitle = this.businessNavigationService.iconTypeMenuTitle;
    this.menuItemsSub = this.businessNavigationService.menuItems$.subscribe(
      (menuItem) => {
        this.menuItems = menuItem;

        this.hasIconTypeMenuItem = !!this.menuItems.filter(
          (item) => item.type === "icon"
        ).length;
      }
    );

    this.isViewLoaded = true;
    this.changeDetectorRefs.detectChanges();
  }

  BusinessServiceStater() {
    this.iconTypeMenuTitle = this.businessNavigationService.iconTypeMenuTitle;
    this.menuItemsSub =
      this.businessNavigationService.menuItemsStater$.subscribe((menuItem) => {
        this.menuItems = menuItem;

        this.hasIconTypeMenuItem = !!this.menuItems.filter(
          (item) => item.type === "icon"
        ).length;
      });
    this.isViewLoaded = true;
    this.changeDetectorRefs.detectChanges();
  }

  BusinessServicePremium() {
    this.iconTypeMenuTitle = this.businessNavigationService.iconTypeMenuTitle;
    this.menuItemsSub = this.businessNavigationService.menuItems2$.subscribe(
      (menuItem) => {
        this.menuItems = menuItem;

        this.hasIconTypeMenuItem = !!this.menuItems.filter(
          (item) => item.type === "icon"
        ).length;
      }
    );

    this.isViewLoaded = true;
    this.changeDetectorRefs.detectChanges();
  }

  hotelAdmin() {
    this.iconTypeMenuTitle = this.propNavService.iconTypeMenuTitle;
    this.menuItemsSub = this.propNavService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;

      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === "icon"
      ).length;
    });

    this.isViewLoaded = true;
    this.changeDetectorRefs.detectChanges();
  }

  orgCustomerSupportAdmin() {
    this.iconTypeMenuTitle = this.orgNavService.iconTypeMenuTitle;
    this.menuItemsSub = this.orgNavService.menuItemsCustomerSupport$.subscribe(
      (menuItem) => {
        this.menuItems = menuItem;

        //Checks item list has any icon type.
        this.hasIconTypeMenuItem = !!this.menuItems.filter(
          (item) => item.type === "icon"
        ).length;
      }
    );

    this.isViewLoaded = true;
    this.changeDetectorRefs.detectChanges();
  }
  orgAdmin() {
    this.iconTypeMenuTitle = this.orgNavService.iconTypeMenuTitle;
    this.menuItemsSub = this.orgNavService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;

      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === "icon"
      ).length;
    });

    this.isViewLoaded = true;
    this.changeDetectorRefs.detectChanges();
  }
  superAdmin() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;

      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === "icon"
      ).length;
    });

    this.isViewLoaded = true;
    this.changeDetectorRefs.detectChanges();
  }
  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  filterArray(array, filters) {
    const filterKeys = Object.keys(filters);
    return array.filter((item) => {
      // validates all filter criteria
      return filterKeys.every((key) => {
        // ignores non-function predicates
        if (typeof filters[key] !== "function") return true;
        return filters[key](item[key]);
      });
    });
  }
  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }
}
