import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL } from "./../../app.component";
import { Notifications } from "./../models/notification.model";
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  getNotificationForProperty(productId: number) {
    return this.http.get<Notifications[]>(
      API_URL + "/api/notification/property?PropertyId=" + productId,
      { observe: "response" }
    );
  }
  saveNotification(notification: Notifications) {
    return this.http.post<Notifications[]>(
      API_URL + "/api/notification/",
      notification,
      { observe: "response" }
    );
  }
  getNotificationForOrder(productId: number) {
    return this.http.get<Notifications[]>(
      API_URL + "/api/notification/getNotification?PropertyId="+productId+"&NotificationType=Order",
      { observe: "response" }
    );
  }
}
