import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TokenStorage } from "../../token.storage";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles?: any;
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  roles?: any;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}
interface UserRole {
  name: string; // Display text
}

@Injectable()
export class BusinessNavigationService {
  roleArray: any = [];
  userId: string;

  isBusinessEssentials: boolean;

  constructor(private token: TokenStorage) {
    this.userId = this.token.getUserId();
    // Logger.log('this.userId in navigation service--'+ this.userId +'-org Id-'+ this.token.getOrganizationId());
  }
  // tslint:disable-next-line: member-ordering
  iconMenu: IMenuItem[] = [
    // {
    //   name: 'Service',
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   icon: 'dashboard',

    //   state: 'master-service',
    //   roles: ['PROP_ADMIN'],
    //   sub: [ { name: 'Dashboard', state: 'home' }],
    // },
    {
      name: "Dashboard",
      type: "link",
      tooltip: "Dashboard",
      roles: ["PROP_ADMIN"],
      icon: "dashboard",
      state: "master-service/home",
    },
    {
      name: "User Management",
      type: "dropDown",
      icon: "book",
      roles: ["PROP_ADMIN"],
      state: "bookone-admin",
      sub: [
        { name: "Add User", state: "create-user/0/0/0" },
        { name: "Manage User", state: "manage-user" },
      ],
    },
    {
      name: "B2B",
      type: "dropDown",
      icon: "book",
      state: "bookone-admin",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Add Company", state: "companyList" }],
    },
    {
      name: "Business",
      type: "dropDown",
      icon: "room",
      state: "master-service",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Business Profile", state: "business-dashboard/profile" }],
    },
    {
      name: "Service",
      type: "dropDown",
      icon: "star",
      state: "master-service",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Service Management", state: "service-list" }],
    },
    {
      name: "Payments",
      type: "dropDown",
      icon: "payment",
      state: "bookone",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Manage Payment", state: "manage-payment" }],
    },

    {
      name: "Order",
      type: "dropDown",
      icon: "store",
      state: "order",
      roles: ["PROP_ADMIN"],
      sub: [
        { name: "Order Management", state: "order-list" },
        { name: "Create Order", state: "create-order" },
      ],
    },
    {
      name: "Reservation",
      type: "dropDown",
      icon: "store",
      state: "master-service",
      roles: ["PROP_ADMIN"],
      sub: [
        { name: "Reservation Management", state: "reservation-list" },
        { name: "Reservation Dashboard", state: "service" },
      ],
    },
    {
      name: "Product",
      type: "dropDown",
      icon: "business_center",
      state: "product",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Product Management", state: "list" }],
    },
    {
      name: "Customer",
      type: "dropDown",
      icon: "group",
      state: "bookone",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Customer Management", state: "customer-list" }],
    },
    {
      name: "Business Promotion",
      type: "dropDown",
      icon: "receipt",
      state: "business-promotion",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Business Promotion", state: "list" }],
    },
    {
      name: "Business Review",
      type: "dropDown",
      icon: "receipt",
      state: "review",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Review List", state: "list" }],
    },
  ];

  staterMenu: IMenuItem[] = [
    {
      name: "Business",
      type: "dropDown",
      icon: "room",
      state: "master-service",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Business Profile", state: "business-dashboard/profile" }],
    },
    {
      name: "Service",
      type: "dropDown",
      icon: "star",
      state: "master-service",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Service Management", state: "service-list" }],
    },
    {
      name: "Reservation",
      type: "dropDown",
      icon: "store",
      state: "master-service",
      roles: ["PROP_ADMIN"],
      sub: [
        { name: "Reservation Management", state: "reservation-list" },
        { name: "Reservation Dashboard", state: "service" },
      ],
    },
    {
      name: "Payments",
      type: "dropDown",
      icon: "payment",
      state: "bookone",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Manage Payment", state: "manage-payment" }],
    },
    {
      name: "Business Review",
      type: "dropDown",
      icon: "receipt",
      state: "review",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Review List", state: "list" }],
    },
  ];

  // tslint:disable-next-line: member-ordering
  iconMenu2: IMenuItem[] = [
    // {
    //   name: 'Service',
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   icon: 'dashboard',

    //   state: 'master-service',
    //   roles: ['PROP_ADMIN'],
    //   sub: [ { name: 'Dashboard', state: 'home' }],
    // },
    {
      name: "Dashboard",
      type: "link",
      tooltip: "Dashboard",
      roles: ["PROP_ADMIN"],
      icon: "dashboard",
      state: "master-service/home",
    },
    // {
    //   name: "Business",
    //   type: "dropDown",
    //   icon: "room",
    //   state: "master-service",
    //   roles: ["PROP_ADMIN"],
    //   sub: [{ name: "Business Profile", state: "business-dashboard/profile" }],
    // },
    {
      name: "User Management",
      type: "dropDown",
      icon: "book",
      roles: ["PROP_ADMIN"],
      state: "bookone-admin",
      sub: [
        { name: "Add User", state: "create-user/0/0/0" },
        { name: "Manage User", state: "manage-user" },
      ],
    },
    {
      name: "B2B",
      type: "dropDown",
      icon: "book",
      state: "bookone-admin",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Add Company", state: "companyList" }],
    },
    {
      name: "Business Profile",
      type: "link",
      icon: "business",
      state: "master-service/business-dashboard/profile",
      roles: ["PROP_ADMIN"],
      // sub: [{ name: "Business Profile", state: "business-dashboard/profile" }],
    },
    // {
    //   name: "Service",
    //   type: "dropDown",
    //   icon: "star",
    //   state: "master-service",
    //   roles: ["PROP_ADMIN"],
    //   sub: [{ name: "Service Management", state: "service-list" }],
    // },
    {
      name: "Service Management",
      type: "link",
      icon: "star",
      state: "master-service/service-list",
      roles: ["PROP_ADMIN"],
      // sub: [{ name: "Service Management", state: "service-list" }],
    },
    {
      name: "Manage Payment",
      type: "link",
      icon: "payment",
      state: "bookone/manage-payment",
      roles: ["PROP_ADMIN"],
    },
    {
      name: "Manage Expense",
      type: "link",
      icon: "money_off",
      state: "bookone/manage-expense",
      roles: ["PROP_ADMIN"],
    },
    {
      name: "Order",
      type: "dropDown",
      icon: "store",
      state: "order",
      roles: ["PROP_ADMIN"],
      sub: [
        { name: "Create Order", state: "create-order" },
        { name: "Order Management", state: "order-list" },
      ],
    },
    {
      name: "Reservation",
      type: "dropDown",
      icon: "store",
      state: "master-service",
      roles: ["PROP_ADMIN"],
      sub: [
        { name: "Reservation Dashboard", state: "service" },
        { name: "Reservation List", state: "reservation-list" },
      ],
    },
    {
      name: "Product",
      type: "dropDown",
      icon: "business_center",
      state: "product",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Product Group List", state: "product-group-list" },
      { name: "Product List", state: "list" }],
    },
    {
      name: "Customer",
      type: "dropDown",
      icon: "group",
      state: "bookone",
      roles: ["PROP_ADMIN"],
      sub: [
        { name: "Add Customer", state: "add-customer" },
        { name: "Customer List", state: "customer-list" },
      ],
    },
    {
      name: "Invoice",
      type: "dropDown",
      icon: "receipt",
      state: "invoice",
      roles: ["PROP_ADMIN"],
      sub: [
        { name: "Create Invoice", state: "invoice-create" },
        { name: "Invoice List", state: "invoice-list" },
      ],
    },
    {
      name: "Business Promotion",
      type: "dropDown",
      icon: "receipt",
      state: "business-promotion",
      roles: ["PROP_ADMIN"],
      sub: [
        { name: "Add Promotion", state: "add" },
        { name: "Promotion List", state: "list" },
      ],
    },
    {
      name: "Business Review",
      type: "link",
      icon: "receipt",
      state: "review/list",
      roles: ["PROP_ADMIN"],
      // sub: [{ name: "Review List", state: "list" }],
    },
  ];

  // tslint:disable-next-line: member-ordering
  separatorMenu: IMenuItem[] = [
    {
      name: "Dashboard",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "master-service",
    },
  ];

  // tslint:disable-next-line: member-ordering
  plainMenu: IMenuItem[] = [
    {
      name: "Dashboard",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "master-service",
    },
  ];

  iconTypeMenuTitle: string = "Frequently Accessed";

  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  menuItems$ = this.menuItems.asObservable();

  menuItems2 = new BehaviorSubject<IMenuItem[]>(this.iconMenu2);
  menuItems2$ = this.menuItems2.asObservable();

  menuItemsStater = new BehaviorSubject<IMenuItem[]>(this.staterMenu);
  menuItemsStater$ = this.menuItemsStater.asObservable();

  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
