<div class="text-center mat-bg-primary pt-16 pb-16">
  <h6 class="m-0 text-white">Notifications</h6>
</div>
<!-- <mat-nav-list class="notification-list" role="list">

  <mat-list-item *ngFor="let n of notifications" class="notific-item" role="listitem" routerLinkActive="open">
    <mat-icon [color]="n.color" class="notific-icon mr-16">{{n.icon}}</mat-icon>
    <a [routerLink]="[n.route || '/dashboard']">
      <div class="mat-list-text">
        <h4 class="message">{{n.message}}</h4>
        <small class="time text-muted">{{n.time}}</small>
      </div>
    </a>
  </mat-list-item>
</mat-nav-list> -->
<mat-nav-list class="notification-list" role="list">
  <!-- Notification item -->
  <mat-list-item
    *ngFor="let n of notifications"
    class="notific-item"
    role="listitem"
    routerLinkActive="open"
  >
    <mat-icon
      *ngIf="
        n.status != null &&
        n.status != undefined &&
        n.status.toLowerCase() === 'confirmed'
      "
      color="primary"
      class="notific-icon mr-1"
      >receipt</mat-icon
    >
    <mat-icon
      *ngIf="
        n.status != null &&
        n.status != undefined &&
        n.status.toLowerCase() === 'cancelled'
      "
      color="warn"
      class="notific-icon mr-1"
      >receipt</mat-icon
    >
    <mat-icon
      *ngIf="
        n.status != null &&
        n.status != undefined &&
        n.status.toLowerCase() === 'submitted'
      "
      color="accent"
      class="notific-icon mr-1"
      >receipt</mat-icon
    >

    <a
      (click)="onRoutePage(n)"
      *ngIf="n.notificationStatus.toLocaleUpperCase() === 'NEW'"
    >
      <div class="mat-list-text">
        <h4 class="message">
          <b
            >{{ n.notificationType }}- {{ n.referenceNumber }}:
            <span *ngIf="n.status != null && n.status != undefined">{{
              capitalize(n.status)
            }}</span></b
          >
        </h4>
        <small class="time text-muted"
          ><b>{{ n.createdDate | date: "dd/MM/yyyy HH:mm" }}</b></small
        >
      </div>
    </a>

    <a
      (click)="onRoutePageRedrect(n)"
      *ngIf="n.notificationStatus.toLocaleUpperCase() != 'NEW'"
    >
      <div class="mat-list-text">
        <h4 class="message">
          {{ n.notificationType }}- {{ n.referenceNumber }}:
          <span *ngIf="n.status != null && n.status != undefined">{{
            capitalize(n.status)
          }}</span>
        </h4>
        <small class="time text-muted">{{
          n.createdDate | date: "dd/MM/yyyy HH:mm"
        }}</small>
      </div>
    </a>
  </mat-list-item>
</mat-nav-list>
<!-- <div class="text-center mt-16" *ngIf="notifications?.length">
  <small><a href="#" (click)="clearAll($event)">Clear all notifications</a></small>
</div> -->
