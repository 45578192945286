import { NgModule, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import { NgxEchartsModule } from "ngx-echarts";
import { rootRouterConfig } from "./app.routing";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ErrorHandlerService } from "./shared/services/error-handler.service";
import { TokenStorage } from "./token.storage";
import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./services/auth-guard.service";
import {
  DEFAULT_TIMEOUT,
  HTTPStatus,
  Interceptor,
  timeoutInterceptorProviders,
} from "./app.interceptor";
import { SharedMaterialModule } from "app/shared/shared-material.module";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { DatePipe } from "@angular/common";
import { BookonePaymentModule } from "./views/bookone-payment/bookone-payment.module";
import { Ng2ImgMaxModule } from "ng2-img-max";
import { InventoryManagementModule } from "./views/inventory-management/inventory-management.module";
import { WebcamModule } from "ngx-webcam";
import { BrowserModule } from "@angular/platform-browser";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { CheckSubscription } from "./checkSubscription";
import { CheckUserType } from "./checkUserType";
import { BookingCalculation } from "./views/bookone/booking/bookingCalculation";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    PerfectScrollbarModule,
    SharedMaterialModule,
    WebcamModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    Ng2ImgMaxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true}),
    RouterModule.forRoot(rootRouterConfig, {
      useHash: false,
      relativeLinkResolution: "legacy",
    }),
  ],
  declarations: [AppComponent],
  providers: [
    AuthService,
    CheckUserType,
    AuthGuard,
    CheckSubscription,
    BookingCalculation,
    TokenStorage,
    HTTPStatus,
    DatePipe,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // REQUIRED IF YOU USE JWT AUTHENTICATION
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    // timeoutInterceptorProviders,
    // httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
