export const environment = {
  production: false,
  appId: 'BookOneLocal',
  homeAddress: 'https://bookonelocal.co.nz',
  apiUrl: 'https://testapi.bookonelocal.co.nz/api-bookone',
  apiUrlInventory: 'https://testapi.bookonelocal.co.nz/api-ims',
  masterProductUrl:"https://masterdata.bookonepms.com",
  apiUrlEms: 'https://testapi.bookonelocal.co.nz/api-ems',
  apiUrllms: 'https://testapi.bookonelocal.co.nz/api-lms',
  cmApiUrl: 'https://testapi.bookonelocal.co.nz/channel-integration',
  addressUrl: 'https://testapi.bookonelocal.co.nz/api-address',
  apiUrlPromotion: 'https://testapi.bookonelocal.co.nz/promotion-api',
  scheduleApiUrl: 'https://testapi.bookonelocal.co.nz/bookone-scheduler',
  apiEglobe : 'https://testapi.bookonelocal.co.nz/api-eglobe',
  apiGoogle: "https://testapi.bookonelocal.co.nz/api-google-hotels",
  country: 'nz',
  showLog: true,
  parentOrganisationId: 1
};
