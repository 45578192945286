import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TokenStorage } from "../../token.storage";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles?: any;
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  roles?: any;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}
interface UserRole {
  name: string; // Display text
}

@Injectable()
export class NavigationService {
  roleArray: any = [];
  userId: string;
  // tslint:disable-next-line: member-ordering
  iconMenu: IMenuItem[] = [
    {
      name: "BOOKONE",
      type: "dropDown",
      tooltip: "Dashboard",
      icon: "dashboard",

      state: "bookone",
      roles: ["SUPER_ADMIN"],
      sub: [{ name: "Dashboard", state: "dashboard" }],
    },

    {
      name: "Super Admin",
      type: "dropDown",
      tooltip: "Bookone Admin",
      icon: "book",
      roles: ["SUPER_ADMIN"],
      sub: [
        {
          name: "User Management",
          type: "dropDown",
          icon: "book",
          roles: ["SUPER_ADMIN"],
          state: "bookone-admin",
          sub: [
            { name: "Add User", state: "create-user/0/0/0" },
            { name: "Manage User", state: "manage-user" },
          ],
        },
        {
          name: "B2B",
          type: "dropDown",
          icon: "book",
          roles: ["SUPER_ADMIN"],
          state: "bookone-admin",
          sub: [{ name: "Add Company", state: "companyList" }],
        },
        {
          name: "Role Management",
          type: "dropDown",
          icon: "book",
          roles: ["SUPER_ADMIN"],
          state: "bookone-admin",
          sub: [
            { name: "Add Role", state: "create-role" },
            { name: "Manage Role", state: "user-role" },
          ],
        },
        {
          name: "Organization Management",
          type: "dropDown",
          icon: "book",
          roles: ["SUPER_ADMIN"],
          state: "bookone-admin",
          sub: [
            { name: "Add Organization", state: "create-organization/0/0" },
            { name: "Manage Organization", state: "manage-organization" },
          ],
        },
        {
          name: "Subscription Management",
          type: "dropDown",
          icon: "book",
          roles: ["SUPER_ADMIN"],
          state: "bookone-admin",
          sub: [
            { name: "Add Subscription", state: "create-subcription/0/0" },
            { name: "Manage Subscription", state: "manage-subcription" },
          ],
        },
        {
          name: "OTA Channel Management",
          type: "dropDown",
          icon: "book",
          roles: ["SUPER_ADMIN"],
          state: "bookone-admin",
          sub: [
            { name: "Manage OTA Channel", state: "manage-channel" },
            {
              name: "Add OTA Channel",
              state: "manage-channel/channel-create/0/0",
            },
          ],
        },

        {
          name: "Address Setup",
          type: "dropDown",
          icon: "book",
          roles: ["SUPER_ADMIN"],
          state: "bookone-admin/address-setup",
          sub: [
            { name: "Country Setup", state: "country" },
            { name: "State or Region Setup", state: "state" },
            { name: "City Setup", state: "city" },
            { name: "Suburb Setup", state: "subrub" },
            { name: "Street Setup", state: "steet" },
          ],
        },

        {
          name: "Payment Gateway",
          type: "dropDown",
          icon: "book",
          roles: ["SUPER_ADMIN"],
          state: "bookone-admin",
          sub: [{ name: "Manage GateWay", state: "manage-payment-getway" }],
        },

        // { name: "Bookone Admin Dashboard", state: "dashboard" },
      ],
    },
  ];

  // tslint:disable-next-line: member-ordering
  separatorMenu: IMenuItem[] = [
    {
      name: "BOOKONE",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "bookone",
    },
  ];

  // tslint:disable-next-line: member-ordering
  plainMenu: IMenuItem[] = [
    {
      name: "BOOKONE",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard",
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  // tslint:disable-next-line: member-ordering
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  constructor(private token: TokenStorage) {
    this.userId = this.token.getUserId();
    // Logger.log('this.userId in navigation service--'+ this.userId +'-org Id-'+ this.token.getOrganizationId());
  }

  onBoardingURL(): string {
    return (
      "property/" +
      this.token.getUserId() +
      "/" +
      this.token.getOrganizationId() +
      ""
    );
  }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
