import { TokenStorage } from "app/token.storage";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApplicationUser } from "../views/sessions/signup/user";
import { Observable, of, throwError } from "rxjs";
import { API_URL } from "../app.component";
import { HttpHeaders } from "@angular/common/http";
import { Token } from "./token";
import { PropertyUser } from "../views/bookone-admin/property-user";
import { Logger } from "./logger.service";
import { Router, ActivatedRoute } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { environment } from "environments/environment";

@Injectable()
export class AuthService {
  currentUser: any;
  return: string;
  signingIn: Boolean;
  isAuthenticated: Boolean;
  JWT_TOKEN = "AuthToken";
  APP_USER = "UserId";
  headers = new HttpHeaders().set("Content-Type", "application/json");
  token;
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorage
  ) {
    this.route.queryParams.subscribe(
      (params) => (this.return = params["return"] || "/")
    );
  }
  logout() {
    sessionStorage.removeItem("AuthToken");
    sessionStorage.removeItem("UserId");
    sessionStorage.removeItem("PropertyId");
    sessionStorage.removeItem("RoomDetails");
    sessionStorage.removeItem("PropertyDetails");
    sessionStorage.removeItem("Roles");
    window.sessionStorage.clear();
    this.router.navigate(["/sessions/signin"]);
  }

  login(credentials): Observable<any> {
    // Logger.log('API_URL'+API_URL);
    this.signingIn = true;
    return this.http.post<Token>(`${environment.apiUrl}/api/user/login`, credentials,  { observe: "response" });
    // let user = credentials.username;
    // let pass = credentials.password;
    // this.signingIn = true;
    // return this.http.post(API_URL + "/api/user/login", { user, pass }, options);
  }
  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
    // return of(DEMO_USER)
    //   .pipe(
    //     map((profile: User) => {
    //       // this.setUserAndToken(this.getJwtToken(), profile, true);
    //       this.signingIn = false;
    //       return profile;
    //     }),
    //     catchError((error) => {
    //       return of(error);
    //     })
    //   );
    /*
      The following code get user data and jwt token is assigned to
      Request header using token.interceptor
      This checks if the existing token is valid when app is reloaded
    */

    return this.http
      .get<ApplicationUser>(
        `${API_URL}/api/user/findById/` + this.tokenStorage.getUserId(),
        { observe: "response" }
      )
      .pipe
      // map((profile: ApplicationUser) => {
      //   // this.setUserAndToken(this.getJwtToken(), profile, true);
      //   return profile;
      // }),
      // catchError((error) => {
      //   this.logout();
      //   return of(error);
      // })
      ();
  }

  createUser(applicationUser: ApplicationUser): Observable<any> {
    Logger.log(applicationUser);
    return this.http.post<ApplicationUser>(
      API_URL + "/api/user/signup",
      applicationUser,
      { observe: "response" }
    );
  }
  createUserByAdmin(propertyUser: PropertyUser): Observable<any> {
    Logger.log(propertyUser);
    return this.http.post<PropertyUser>(
      API_URL + "/api/user/signup",
      propertyUser,
      { observe: "response" }
    );
  }

  updateUserByAdmin(propertyUser: PropertyUser): Observable<any> {
    Logger.log(propertyUser);
    return this.http.post<PropertyUser>(
      API_URL + "/api/user/updateUser",
      propertyUser,
      { observe: "response" }
    );
  }
  updateUser(applicationUser: ApplicationUser): Observable<any> {
    //Logger.log(applicationUser);
    return this.http.post<ApplicationUser>(
      API_URL + "/api/user/update",
      applicationUser,
      { observe: "response" }
    );
  }
  updateProfile(applicationUser: ApplicationUser): Observable<any> {
    return this.http.post<ApplicationUser>(
      API_URL + "/api/user/profileUpdate",
      applicationUser,
      { observe: "response" }
    );
  }

  updateProfileForPropertyUser(applicationUser: PropertyUser): Observable<any> {
    return this.http.post<PropertyUser>(
      API_URL + "/api/user/profileUpdate",
      applicationUser,
      { observe: "response" }
    );
  }

  updatePassword(applicationUser: ApplicationUser): Observable<any> {
    return this.http.post<ApplicationUser>(
      API_URL + "/api/user/updatePassword",
      applicationUser,
      { observe: "response" }
    );
  }
  updateProfilePicture(applicationUser: ApplicationUser): Observable<any> {
    return this.http.post<ApplicationUser>(
      API_URL + "/api/user/updateProfilePic",
      applicationUser,
      { observe: "response" }
    );
  }
  getAll(): Observable<ApplicationUser[]> {
    //Logger.log(API_URL + '/users');
    return this.http.get<ApplicationUser[]>(API_URL + "/api/user/findAll");
  }
  getAllUser(): Observable<PropertyUser[]> {
    //Logger.log(API_URL + '/users');
    return this.http.get<PropertyUser[]>(API_URL + "/api/user/findAll");
  }
  getAllOrganizationUser(organizationId: string): Observable<PropertyUser[]> {
    //Logger.log(API_URL + '/api/user/organisation/3405');
    return this.http.get<PropertyUser[]>(
      API_URL + "/api/user/organisation/" + organizationId
    );
  }
  getUserDetailsByUserName(userName: string): Observable<any> {
    return this.http.get<ApplicationUser>(
      API_URL + "/api/user/findByName/" + userName,
      { observe: "response" }
    );
  }
  getUserByUsername(userName: string): Observable<any> {
    return this.http.get<ApplicationUser>(
      API_URL + "/api/user/findByName/" + userName,
      { observe: "response" }
    );
  }
  getUserByUserId(userId: string): Observable<any> {
    return this.http.get<ApplicationUser>(
      API_URL + "/api/user/findById/" + userId,
      { observe: "response" }
    );
  }
  getOrganizationUserByUserId(userId: string): Observable<any> {
    return this.http.get<PropertyUser>(
      API_URL + "/api/user/findById/" + userId,
      { observe: "response" }
    );
  }

  getAllPropertyUser(propertyId: string): Observable<PropertyUser[]> {
    return this.http.get<PropertyUser[]>(
      API_URL + "/api/property/" + propertyId + "/users"
    );
  }


  getAllUserByPropertyId(propertyId: string): Observable<PropertyUser[]> {
    return this.http.get<PropertyUser[]>(
      API_URL + "/api/property/propertyId/" + propertyId + "/users"
    );
  }
}
