import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class Logger {
  static info(value: any, ...rest: any[]): void {
    if (!environment.production && environment.showLog == true){
        console.info(value, rest);
    }
}

static log(value: any, ...rest: any[]): void {
    if (!environment.production && environment.showLog == true){
        console.log(value, rest);}
}

static warn(value: any, ...rest: any[]): void {
    if (!environment.production && environment.showLog == true){
        console.warn(value, rest);}
}

static error(value: any, ...rest: any[]): void {
    if (!environment.production && environment.showLog == true){
        console.error(value, rest);}
}
}
