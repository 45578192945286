import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationService } from "../../../shared/services/navigation.service";
import { OrgNavigationService } from "../../../shared/services/OrgNavigationService";
import { PropNavigationService } from "../../../shared/services/PropNavigationService";
import { Subscription } from "rxjs";
import { TokenStorage } from './../../../token.storage';
import { CheckUserType } from '../../../checkUserType';
import { BusinessNavigationService } from 'app/shared/services/BusinessNavigationService';
import { Logger } from 'app/services/logger.service';

@Component({
  selector: 'app-sidebar-top',
  templateUrl: './sidebar-top.component.html'
})
export class SidebarTopComponent implements OnInit, OnDestroy, AfterViewInit {
  // private sidebarPS: PerfectScrollbar;
  public menuItems: any[];
  checkUserType: CheckUserType;
  role: any = [];
  private menuItemsSub: Subscription;
  constructor(
    private businessNavigationService: BusinessNavigationService,
    private propNavService: PropNavigationService,
    private orgNavService: OrgNavigationService,
    private token: TokenStorage,
    private navService: NavigationService
  ) {
    this.checkUserType = new CheckUserType();

    this.role = [];
    JSON.parse(this.token.getRole()).forEach(item => {
      this.role.push(item);
    });
  }

  ngOnInit() {
    if (this.checkUserType.isSuperAdmin(this.role[0]) == true) {
      this.superAdmin();
    }
    else if (this.checkUserType.isOrganization(this.role[0]) == true) {
      this.orgAdmin();
    }
    else if (this.checkUserType.isOrganizationCustomerSupport(this.role[0]) == true) {
      Logger.log('customer support side bar top');
      this.orgAdminCustomerSupport();
    }
    else if (this.checkUserType.isHotelAdmin(this.role[0]) == true) {
      if (this.token.getProperty().businessType.toLocaleLowerCase() !== 'accommodation' && this.token.getProperty().propertyStatus == "COMPLETED") {
        this.BusinessService();
      }
      else {
        this.hotelAdmin();
      }

    }


  }

  orgAdminCustomerSupport() {
    this.menuItemsSub = this.orgNavService.menuItemsCustomerSupport$.subscribe(menuItem => {
      this.menuItems = menuItem.filter(item => item.type !== 'icon' && item.type !== 'separator');
      // Logger.log('  this.menuItems'+JSON.stringify(  this.menuItems));
    });
  }

  BusinessService() {

    this.menuItemsSub = this.businessNavigationService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem.filter(item => item.type !== 'icon' && item.type !== 'separator');
      // Logger.log('  this.menuItems'+JSON.stringify(  this.menuItems));
    });
  }

  hotelAdmin() {
    this.menuItemsSub = this.propNavService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem.filter(item => item.type !== 'icon' && item.type !== 'separator');
      // Logger.log('  this.menuItems'+JSON.stringify(  this.menuItems));
    });
  }
  orgAdmin() {
    this.menuItemsSub = this.orgNavService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem.filter(item => item.type !== 'icon' && item.type !== 'separator');
      // Logger.log('  this.menuItems'+JSON.stringify(  this.menuItems));
    });
  }
  superAdmin() {
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem.filter(item => item.type !== 'icon' && item.type !== 'separator');
      // Logger.log('  this.menuItems'+JSON.stringify(  this.menuItems));
    });
  }
  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.sidebarPS = new PerfectScrollbar('#sidebar-top-scroll-area', {
    //     suppressScrollX: true
    //   })
    // })
  }
  ngOnDestroy() {
    // if(this.sidebarPS) {
    //   this.sidebarPS.destroy();
    // }
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe()
    }
  }

}
