import { ClosedDays } from './closedDays';
import { OpenDays } from './openDays';
import { BusinessServiceTypes } from './businessServiceTypes';
import { TaxDetails } from 'app/views/bookone-onboarding/tax-details/model/TaxDetails';

export class BusinessServiceDtoList {
  id: number;
  closedDays: ClosedDays[];
  openDays: OpenDays[];
  policy: string;
  name: string;
  brandName:string;
  brandSlogan: string;

  maxLeadTime	 : number;
  minLeadTime	: number;
  stdPrepTime	: number;

  description: string;
  propertyId: number;
  businessServiceTypes: BusinessServiceTypes[];
  serviceCloseList: [
    {
      day: string;
    }
  ];
  serviceOpenList: [
    {
      breakFromTime: string;
      breakToTime: string;
      closingTime: string;
      day: string;
      openingTime: string;
    }
  ];

  businessLocationName: string;
  customerLocationName: string;
  canChangeBusinessAddress: boolean;
  provideBusinessAndCustomerAddress: boolean;
  serviceChargePercentage: number;
  businessTermLocation: string;
  businessTermResource: string;

  businessProductName: string;
  businessServiceName: string;
  bookingButtonLabelText : string;
  businessTypeId : number;

  taxDetails : TaxDetails[];
  priceInclusiveOfTax: boolean;
  active : boolean;
  serviceReservation: boolean;
  logoUrl: string;
  gstNumber: string;
  phoneNumber: string;

  constructor() {}
}
